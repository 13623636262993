<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/logo_sob.svg"
            height="70"
            width="80"
            alt=""
            srcset=""
          >
          <img
            src="@/assets/images/logo/deno.svg"
            height="40"
            width="103"
            class="mt-1"
            alt=""
            srcset=""
          >
        </b-link>
        <b-card-title class="mb-1">
          Réinitialisation de mot de passe ? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Suivez les instructions pour réinitialiser votre compte.
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="formSubmit"
        >
          <b-form-group
            label="Mot de passe"
          >
            <b-input-group
              class="input-group-merge"
              :class="{ 'is-invalid': $v.reset.new.$error }"
            >
              <b-form-input
                v-model="$v.reset.new.$model"
                :class="{
                  'is-invalid': $v.reset.new.$error
                }"
                :type="passwordFieldType"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
              <div
                v-if="$v.reset.new.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.reset.new.required"
                >Mot de passe requis</span>
                <span
                  v-if="!$v.reset.new.password"
                >Le mot de passe doit contenir 10 caractères y compris une majuscule et une lettre minuscule, un nombre et un caractère spécial.</span>
              </div>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Confirmation"
          >
            <b-form-input
              v-model="$v.reset.confirm.$model"
              :class="{
                'is-invalid': $v.reset.confirm.$error
              }"
              type="password"
            />
            <div
              v-if="$v.reset.confirm.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.reset.confirm.required"
              >Mot de passe requis</span>
              <span
                v-if="!$v.reset.confirm.sameAs"
              >Mots de passe non identiques</span>
            </div>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            type="submit"
            :disabled="process"
            block
          >
            <b-spinner
              v-if="process"
              small
            />
            <span
              v-show="!process"
              class="ml-1"
            > Réinitialiser</span>
          </b-button>
        </b-form>

        <div class="divider my-2">
          <div class="divider-text text-primary font-weight-bold h5">
            SOBEMAP
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  required, helpers, sameAs,
} from 'vuelidate/lib/validators'

export default {
  components: {
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loaded: false,
      userEmail: null,
      callBackUrl: `${window.location.hostname}/reset-password`,
      reset: {
        new: null,
        confirm: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      process: 'initResetProcess',
      error: 'initResetError',
      success: 'initResetSuccess',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  validations: {
    reset: {
      new: {
        required,
        password: helpers.regex(
          'password',
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{10,}$/,
        ),
      },
      confirm: {
        required,
        // eslint-disable-next-line func-names
        sameAs: sameAs(function () {
          return this.reset.new
        }),
      },
    },
  },
  watch: {
    success(val) {
      if (val) {
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Récupération de compte',
            text: 'Compte récupéré avec succès, veuillez vous connecter.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 2000)
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Récupération de compte',
            text: 'Une erreur est survenue.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['resetPassword']),
    resetForm() {
      this.reset = {
        new: null,
        confirm: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    async formSubmit() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.resetPassword({
        id: this.$route.params.id,
        token: this.$route.params.token,
        password: this.reset.new,
        secondPassword: this.reset.confirm,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
